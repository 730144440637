import * as Sentry from '@sentry/vue';
export default defineNuxtPlugin({
   name: 'sentry',
   parallel: true,
   async setup(nuxtApp) {
      const vueApp = nuxtApp.vueApp;

      const config = useRuntimeConfig();
      const router = useRouter();
      const url = window.location.origin.split('://')[1];
      if (config.public.NODE_ENV != 'local' && !url.startsWith('localhost'))
         Sentry.init({
            app: vueApp,
            dsn: config.public.SENTRY_DSN_PUBLIC as string,
            environment: config.public.NODE_ENV,
            integrations: [
               Sentry.replayIntegration(),
               Sentry.browserTracingIntegration({
                  router,
                  routeLabel: 'path',
               }),
               Sentry.breadcrumbsIntegration({
                  console: true,
                  dom: true,
                  fetch: true,
                  history: true,
                  xhr: false,
               }),
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
         });
   },
});
